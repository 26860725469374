import { Directive, ElementRef, Renderer } from '@angular/core';

@Directive({
    selector: "[getHeightWidth]",
    host: {
        '(click)': "show()"
    }
})

export class GetEleDirective {

    constructor(private el: ElementRef) {

    }
    show() {
        console.log(this.el);
        console.log(this.el.nativeElement);
        console.log('height---' + this.el.nativeElement.naturalHeight);
        console.log('width---' + this.el.nativeElement.naturalWidth);

        this.el.nativeElement.width = this.el.nativeElement.naturalWidth;
        this.el.nativeElement.height = this.el.nativeElement.naturalHeight;
    }
}