import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

// used to create fake backend
import { fakeBackendProvider } from "./_helpers/fake-backend";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { AlertComponent } from "./_components/alert.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { DataService } from "./data.service";
import { ListComponent } from "./list/list.component";
import { ChecklistComponent } from "./checklist/checklist.component";
import { FormComponent } from "./form/form.component";
import { MatTableModule } from "@angular/material/table";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatInputModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatCardModule,
  MatPaginatorModule,
  MatStepperModule,
  MatFormFieldModule,
  MatButtonModule,
  MatSelectModule,
  MatIconModule,
  MatToolbarModule,
  MatMenuModule,
  MatTabsModule,
  MatAutocompleteModule,
} from "@angular/material";

import { ModalComponent } from "./modal/modal.component";
import { GetEleDirective } from "./_helpers/imageproperties";
import { AuthenticationService } from "./_services/authentication.service";
import { KeysPipe } from "./keys.pipe";
import { KeysPipeHeader } from "./keys.pipe.header";
import { TransferComponent } from "./transfer/transfer.component";
import { ImageCropperModule } from "ngx-image-cropper";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatCardModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSnackBarModule,
    HttpClientModule,
    MatCardModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    ImageCropperModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    ListComponent,
    ChecklistComponent,
    FormComponent,
    ModalComponent,
    GetEleDirective,
    KeysPipe,
    KeysPipeHeader,
    TransferComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    DataService,
    AuthenticationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
