import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./_guards/auth.guard";
import { ListComponent } from "./list/list.component";
import { ChecklistComponent } from "./checklist/checklist.component";
import { FormComponent } from "./form/form.component";
import { TransferComponent } from "./transfer/transfer.component";

const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "list", component: ListComponent, canActivate: [AuthGuard] },
  {
    path: "checklist",
    component: ChecklistComponent,
    canActivate: [AuthGuard],
  },
  { path: "form", component: FormComponent },
  { path: "transfer", component: TransferComponent, canActivate: [AuthGuard] },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
