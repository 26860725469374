import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { DataService } from "../data.service";
import { AutomationService } from "../_services/automation.service";
import { List } from "../_models/list";
import { first } from "rxjs/operators";
import { UserService } from "../_services/user.service";
import { AuthenticationService } from "../_services/authentication.service";
import { Subscription } from "rxjs";
import { User } from "../_models/user";
import { SessionData } from "../_models/sessiondata";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
//   { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
//   { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
//   { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
//   { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
//   { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
//   { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
//   { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
//   { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
//   { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
// ];

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = [
    "employeeGender",
    "employeeName",
    "employeePhone",
    "employeeEmail",
    "status",
  ];
  dataSource = new MatTableDataSource<List>();
  currentUser: User;
  currentUserSubscription: Subscription;
  lists: List[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  items: List[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private automationService: AutomationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;
      }
    );
  }

  ngOnInit() {
    this.automationService
      .getListForSpecificStatus(
        this.currentUser.selectedStage,
        this.currentUser.id
      )
      .pipe(first())
      .subscribe((items) => {
        this.items = items;
        console.log("LOOP : ");
        console.log(this.items);
        console.log(this.dataSource);
        this.dataSource = new MatTableDataSource<List>(this.items);
        this.dataSource.paginator = this.paginator;
      });
  }

  itemClicked(item) {
    console.log("ITEM CLICKED : ", item);
    this.currentUser.selectedUserId = item.employeeId;
    this.currentUser.selectedUserName = item.employeeName;
    localStorage.setItem("user", JSON.stringify(this.currentUser));
    if (this.currentUser.selectedStage === "reviewFieldForm") {
      this.router.navigate(["/checklist"], {
        queryParams: { stage: 1, employeeId: item.employeeId },
      });
    } else if (this.currentUser.selectedStage === "reviewDocumentForm") {
      this.router.navigate(["/checklist"], {
        queryParams: { stage: 2, employeeId: item.employeeId },
      });
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
