import { Component, OnInit, ViewChild } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { AutomationService } from "../_services/automation.service";
import {
  MatSnackBar,
  MatVerticalStepper,
  MatSelectModule,
} from "@angular/material";
import {
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["./transfer.component.css"],
})
export class TransferComponent implements OnInit {
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  selection: SelectionModel<any>;
  fromModeratorList: any;
  toModeratorList: any;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  @ViewChild(MatPaginator, {}) paginator: MatPaginator;

  ngOnInit() {
    this.firstFormGroup = new FormGroup({
      transferFromModerator: new FormControl(
        "Select Moderator",
        Validators.required
      ),
    });
    this.secondFormGroup = new FormGroup({
      filter: new FormControl(""),
    });
    this.thirdFormGroup = new FormGroup({
      transferToModerator: new FormControl(
        "Select Moderator",
        Validators.required
      ),
    });
  }

  constructor(
    private AutomationService: AutomationService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.openSnackBar("Please select a moderator to continue.");
    this.AutomationService.getAllModerators().subscribe(
      (result) => {
        console.log(result);

        if (result) {
          //Allow user to continue
          console.log(result);
          this.fromModeratorList = result.moderators;
          this.toModeratorList = result.moderators;
        } else {
          //Throw error
          this.openSnackBar("Cannot fetch Moderators");
        }
      },
      (error) => {
        console.log(error);
        //this.openSnackBar(error.error.error.message);
      }
    );
  }
  listcandidates: any[] = [];

  transferFromModerator = "";
  transferToModerator = "";

  fetchAllCandidates() {
    this.openSnackBar("Fetching the candidates. Please click on next");
    this.AutomationService.getAllCandidatesforModerator(
      this.firstFormGroup.controls["transferFromModerator"].value
    ).subscribe(
      (result) => {
        if (result) {
          //Allow user to continue
          console.log(result);

          // this.listcandidates = [
          //   {
          //     select: false,
          //     reporteeName: "Vinesh",
          //     reporteeEmail: "vineshcandidate@quickwork.co",
          //   },
          //   {
          //     select: false,
          //     reporteeName: "Ayyub Candidate",
          //     reporteeEmail: "ayyub.candidate@quickwork.co",
          //   },
          // ];
          this.listcandidates = result.candidates;
          this.dataSource = new MatTableDataSource<any>(this.listcandidates);
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<any>(true, []);
          this.displayedColumns = ["select", "employeeName", "employeeEmail"];
        } else {
          //Throw error
          this.openSnackBar("Cannot fetch Candidates");
        }
      },
      (error) => {
        console.log(error);
        this.listcandidates = [];
        this.dataSource = new MatTableDataSource<any>(this.listcandidates);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<any>(true, []);
        this.displayedColumns = ["select", "employeeName", "employeeEmail"];
        this.openSnackBar("Cannot fetch Candidates");
      }
    );
  }

  filter = "";

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.clearAll()
      : this.dataSource.data.forEach((row) => this.toggleAll(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  transfer() {
    const candidateArray = this.listcandidates.filter((obj) => {
      if (obj.isChecked == true) {
        return obj;
      }
    });

    if (
      candidateArray.length < 1 ||
      this.firstFormGroup.controls["transferFromModerator"].value ==
        undefined ||
      this.firstFormGroup.controls["transferFromModerator"].value == "" ||
      this.thirdFormGroup.controls["transferToModerator"].value == undefined ||
      this.thirdFormGroup.controls["transferToModerator"].value == ""
    ) {
      this.openSnackBar("Missing Mandatory Information");
      return;
    }

    if (
      this.firstFormGroup.controls["transferFromModerator"].value ==
      this.thirdFormGroup.controls["transferToModerator"].value
    ) {
      this.openSnackBar("Cannot continue because MCs are same");
      return;
    }

    const payload = {};
    payload["candidateArray"] = candidateArray;
    payload["fromModeratorId"] = this.firstFormGroup.controls[
      "transferFromModerator"
    ].value;
    payload["toModeratorId"] = this.thirdFormGroup.controls[
      "transferToModerator"
    ].value;
    console.log(JSON.stringify(payload));

    this.openSnackBar("The transfer request is processing.");
    //this.router.navigate(["/home"]);

    this.AutomationService.transferModerator(JSON.stringify(payload)).subscribe(
      (result) => {
        console.log(result);
        if (result != null) {
          //Allow user to continue
          this.openSnackBar(
            "The transfer request is processing. You will receive an email after the transfer is complete."
          );
          setTimeout(function () {
            this.router.navigate(["/home"]);
          }, 3000);
        } else {
          //Throw error
          this.openSnackBar("Something went wrong");
        }
      },
      (error) => {
        console.log(error);
        this.openSnackBar(error.error.error.message);
      }
    );
  }

  toggle(row) {
    this.selection.toggle(row);
    if (row.isChecked) {
      row.isChecked = false;
    } else {
      row.isChecked = true;
    }
    console.log(row);
    this.filter = "";
    this.applyFilter("");
  }
  toggleAll(row) {
    this.selection.select(row), (row.isChecked = true);
  }

  clearAll() {
    this.selection.clear();
    this.dataSource.data.forEach((row) => (row.isChecked = false));
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "", {
      duration: 3000,
    });
  }
}
