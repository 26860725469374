import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { DataService } from '../data.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '../_models/user';

let authentication;

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {

    user: User;
    currentUserSubject: BehaviorSubject<User>;
    loginFormSendOTP: FormGroup;
    loginFormVerifyOTP: FormGroup;

    loadingVerifyOTP = false;
    loadingSendOTP = false;
    submittedSendOTP = false;
    submittedVerifyOTP = false;
    returnUrl: string;
    error: string;
    showOTPField: boolean = false;
    login_step_1: boolean = false;
    login_button_text: string;

    constructor(
        private dataService: DataService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        authentication = this.authenticationService;

        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    cancelLogin() {
        localStorage.clear();
        this.loadingSendOTP = false;
        this.submittedSendOTP = false;
        this.submittedVerifyOTP = false;
        this.loadingVerifyOTP = false;
        this.changeScreenAccordingToContext();
    }

    ngOnInit() {
        this.changeScreenAccordingToContext();
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get sf() { return this.loginFormSendOTP.controls; }
    get vf() { return this.loginFormVerifyOTP.controls; }


    changeScreenAccordingToContext() {
        var currentloginstep = localStorage.getItem("login_step_1");

        console.log("currentloginstep : " + currentloginstep);
        console.log(this.loginFormSendOTP);
        console.log(this.loginFormVerifyOTP);

        if (currentloginstep === 'true') {

            this.loginFormVerifyOTP = this.formBuilder.group({
                OTP: ['', Validators.required]
            });

            this.login_step_1 = true;
            this.showOTPField = true;
        } else {
            this.loginFormSendOTP = null;
            this.loginFormSendOTP = this.formBuilder.group({
                username: ['', Validators.required]
            });

            this.login_step_1 = false;
            this.showOTPField = false;

        }

    }

    onSendOTPSubmit() {
        this.submittedSendOTP = true;
        console.log('onSendOTPSubmit');
        if (this.loginFormSendOTP.invalid) {
            this.error = "required";
            return;
        }
        console.log(this.sf.username.value);
        this.loadingSendOTP = true;
        this.authenticationService.sendOTP(this.sf.username.value)
            .subscribe(data => {
                this.loadingSendOTP = false;
                localStorage.setItem('login_step_1', 'true');
                localStorage.setItem('guest', this.sf.username.value);
                this.changeScreenAccordingToContext();
            }, error => {
                console.log(error);
                this.error = "Something went wrong!";
                this.alertService.error(this.error);
                this.loadingSendOTP = false;
            });
    }

    onVerifyOTPSubmit() {
        this.submittedVerifyOTP = true;
        console.log('onVerifyOTPSubmit');
        if (this.loginFormVerifyOTP.invalid) {
            this.error = "required";
            return;
        }
        console.log(this.vf.OTP.value);
        this.loadingVerifyOTP = true;

        var self = this;

        this.authenticationService.verifyOTP(this.vf.OTP.value, localStorage.getItem('guest')).subscribe(data => {
            console.log("OUTPUT : ");
            
            console.log(data);

            if (data.hasOwnProperty('error')) {
                
                if (data.error == "Sorry!! You can not login to this dashboard.") {
                    this.error = "Sorry!! You can not login to this dashboard.";
                    this.alertService.error(this.error);
                    localStorage.clear();
                    this.loadingSendOTP = false;
                    this.submittedSendOTP = false;
                    this.submittedVerifyOTP = false;
                    this.loadingVerifyOTP = false;
                    this.changeScreenAccordingToContext();
                } else {
                    this.error = "Something went wrong while verifing OTP";
                    this.alertService.error(this.error);
                    this.loadingVerifyOTP = false;
                }
            } else {
                this.loadingVerifyOTP = false;
                localStorage.removeItem('login_step_1');
                localStorage.setItem('userId', localStorage.getItem('guest'));
                localStorage.removeItem('guest');
                this.router.navigate([this.returnUrl]);
            }


        }, error => {
            this.error = "Something went wrong while verifing OTP";
            this.alertService.error(this.error);
            this.loadingVerifyOTP = false;
            // console.log("Error : " + error);
        });
    }


    updatLocalStorageandRedirect(self, identity, data) {
        console.log("EXECUTED 0");
        localStorage.setItem('user', JSON.stringify(data));
        console.log("ABCD " + this.returnUrl);

        try {

            self.NgZone.run(() => {
                self.router.navigate(['/home']);
            });
            // this.router.navigate(['/home']);
        } catch (error) {
            console.log("error : " + error);

            this.router.navigate(['']);

        }
    }
}