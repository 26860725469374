import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { AutomationService } from '../_services/automation.service';
import { Status } from '../_models/status';
import { DataService } from '../data.service';
import { Alert } from 'selenium-webdriver';
import { SessionData } from '../_models/sessiondata';

@Component(
    {
        templateUrl: 'home.component.html',
        styleUrls: ['home.component.css']
    }
)
export class HomeComponent implements OnInit, OnDestroy {
    currentUser: User;
    currentUserSubscription: Subscription;
    users: User[] = [];

    status: Status;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private automationService: AutomationService,
        private router: Router,
        private dataService: DataService
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            console.log("COULD NOT SUBSCRIB");
            
            this.currentUser = user;
        });
    }

    ngOnInit() {
        this.fetchAllStatuses();
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.currentUserSubscription.unsubscribe();
    }

    deleteUser(id: number) {
        this.userService.delete(id).pipe(first()).subscribe(() => {
            this.loadAllUsers()
        });
    }

    public getList(currentStatus: string, count: number) {
        this.currentUser.selectedStage = currentStatus;
        localStorage.setItem('user', JSON.stringify(this.currentUser));
        console.log("getListOfPendingDocumentUpload() called {} count : {}", currentStatus, count);
        if (count == 0) {
            alert("This stage does not have any documents!!")
        } else {
            this.router.navigate(['/list']);
        }



    }

    private fetchAllStatuses() {
        console.log("CURRENT USER : ");
        console.log(this.currentUser);

        this.automationService.getAllStatus(this.currentUser.id).pipe(first())
            .subscribe(
                data => {
                    this.status = data;
                    console.log("statuses : ", this.status);
                },
                error => {
                    console.log("Error occured!!!");
                });
    }

    private loadAllUsers() {
        this.userService.getAll().pipe(first()).subscribe(users => {
            this.users = users;
        });
    }
}