import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "keys" })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    console.log("value ", value);
    let keys = [];
    for (let key in value) {
      keys.push(key);
    }
    // console.log("keys ", keys);
    return keys;
  }
}
