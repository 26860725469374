import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { User } from "../_models/user";
import { SessionData } from "../_models/sessiondata";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  // http://svatantramicrofin-staging.quickwork.co

  //OLD URL FOR JAVA CLIENT

  //public baseUrl: String =  "https://svatantramicrofin-staging.quickwork.co/onboarding/api/v1/authenticate";

  public baseUrl: String =
    "https://apim.quickwork.co/ayyub/onboarding/migrated/v1/authenticate";

  //public baseUrl: String = "http://localhost:8080/onboarding/api/v1/authenticate";
  // public baseUrl: String =
  //   "http://svatantramicrofinhrstagingbackv1.quickwork.co:8080/onboarding/api/v1/authenticate";
  public headers = new Headers({
    "Content-Type": "application/x-www-form-urlencoded",
  });

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      apikey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
    }),
  };

  constructor(private http: HttpClient) {
    console.log("FETCH");

    console.log(JSON.parse(localStorage.getItem("user")));

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  sendOTP(input: string) {
    const params = new HttpParams({
      fromObject: {
        input: input,
      },
    });

    return this.http
      .post(`${this.baseUrl}/send_otp`, params, this.httpOptions)
      .pipe((status) => {
        return status;
      });
  }

  verifyOTP(otp: string, email: string) {
    const params = new HttpParams({
      fromObject: {
        otp: otp,
        input: email,
      },
    });

    return this.http
      .post<any>(`${this.baseUrl}/verify_otp`, params, this.httpOptions)
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response

          console.log("LOG");
          console.log(user);

          if (user != null && user != undefined) {
            if (user.hasOwnProperty("error")) {
              return user;
            } else if (user.hasOwnProperty("Role")) {
              if (user.Role == "MODERATOR_ROLE") {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
                this.currentUserSubject.next(user);
              } else {
                user.error = "Sorry!! You can not login to this dashboard.";
                return user;
              }
            } else {
              user.error = "Sorry!! You can not login to this dashboard.";
              return user;
            }
          } else {
            user.error = "Sorry!! You can not login to this dashboard.";
            return user;
          }

          return user;
        })
      );
  }

  logoutUser(channelIdentity: string, employeeId: string) {
    const params = new HttpParams({
      fromObject: {
        channelIdentity: channelIdentity,
        employeeId: employeeId,
      },
    });

    this.logout();

    return this.http
      .post(`${this.baseUrl}/logout`, params, this.httpOptions)
      .pipe((status) => {
        return status;
      });
  }

  login(username: string, pageId: string) {
    return this.http
      .post<any>(`${this.baseUrl}/users/authenticate`, { username, pageId })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  sendEmail() {
    return this.http.get<any>(
      `https://automation.quickwork.co/staticwebhook/api/http_app/notify/5d5e8ad1ab25404b24476a88/surveyjs_submission`
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    this.currentUserSubject.next(null);
  }
}
