import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "keysHeader" })
export class KeysPipeHeader implements PipeTransform {
  transform(value, args: string[]): any {
    console.log("value ", value);
    let keys = [];

    var L = value.length;
    for (var i = 0; i < L; i++) {
      var obj = value[i];
      for (var j in obj) {
        if (keys.indexOf(j) == -1) {
          if (j != "no") {
            keys.push(j);
          }
        }
      }
    }
    keys.unshift("no");
    return keys;
  }
}
